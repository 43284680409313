<template>
  <v-row>
    <v-col
      cols="12"
      sm="12"
      md="12"
      style="margin-bottom: 10px;background-color: #CCCCCC80;"
    >
            <select-image 
              :image="background"
              :onChange="handleChangeBackground"
              :locale="locale"
              :imageWidth="1920"
              :imageHeight="1080"
              :aspectRatio="16/9"
              :folder="`weekly-menu/${workspaceID}`"
            />
    </v-col>
    <v-col
      cols="12"
      sm="12"
      md="12"
      style="margin-bottom: 10px;background-color: #CCCCCC80;"
    >
      <v-toolbar dense elevation="0" dark>
        <v-toolbar-title>Comida</v-toolbar-title>
        <v-spacer/>
        <v-btn
          @click="handleAddMeal"
          color="primary"
        >
          {{ $t('common.add', locale) }}
        </v-btn>
      </v-toolbar>
      <v-layout wrap>
        <meal-item
          v-for="meal of mealConfig"
          :key="meal.id"
          :editedItem="meal"
          :onChange="handleChangeMeal"
          :onDelete="handleDeleteMeal"
        />
      </v-layout>
    </v-col>

    <v-col
      cols="12"
      sm="12"
      md="12"
      style="margin-bottom: 10px;background-color: #CCCCCC80;"
    >
      <v-toolbar dense elevation="0" dark>
        <v-toolbar-title>Plato</v-toolbar-title>
        <v-spacer/>
        <v-btn
          @click="handleAddRow"
          color="primary"
        >
          {{ $t('common.add', locale) }}
        </v-btn>
      </v-toolbar>
      <v-layout wrap>
        <row-item
          v-for="row of rowConfig"
          :key="row.id"
          :editedItem="row"
          :onChange="handleChangeRow"
          :onDelete="handleDeleteRow"
          :locale="locale"
        />
      </v-layout>
    </v-col>

    <v-col
      cols="12"
      sm="12"
      md="12"
      style="background-color: #CCCCCC80;"
    >
      <v-toolbar dense elevation="0" dark>
        <v-toolbar-title>Semana</v-toolbar-title>
        <v-spacer/>
        <v-btn
          @click="handleAddWeek"
          color="primary"
        >
          {{ $t('common.add', locale) }}
        </v-btn>
      </v-toolbar>
    </v-col>
    <v-col
      cols="12"
      sm="12"
      md="12"
      v-for="item of weekConfig"
      :key="item.id"
      style="background-color: #CCCCCC80;"
    >
      <v-layout wrap>
        <v-flex xs6>
          <v-text-field
            label="Nombre"
            v-model="item.name"
            hide-details
            dense
            outlined
            @input="handleChangeWeek"
          />
        </v-flex>
        <v-flex xs6 style="padding: 0 0 0 10px;">
          <v-text-field
            label="Date"
            v-model="item.date"
            hide-details
            dense
            outlined
            @input="handleChangeWeek"
            append-outer-icon="mdi-delete"
            @click:append-outer="handleDeleteWeek(item.id)"
          />
        </v-flex>
      </v-layout>
    </v-col>
    <v-col
      cols="12"
      sm="12"
      md="12"
    >
    <a :href="urlXML"><small>{{ urlXML }}</small></a>
    </v-col>
  </v-row>
</template>
<script>
import utils from '@/services/utils'
import SelectImage from '@/components/selectImage/Index'
import MealItem from './MealItem'
import RowItem from './rowItem/Index'
export default {
  components: {
    SelectImage,
    MealItem,
    RowItem,
  },
  props: {
    editedItem: {
      type: Object,
      default: null,
    },
    onChange: {
      type: Function,
      required: true,
    },
    locale: {
      type: String,
      default: null,
    },
    workspaceID: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    config: {},
    newBackground: null,
    background: null,
    weekConfig: [],
    mealConfig: [],
    rowConfig: [],
  }),
  computed: {
    isDev () {
      return process.env.VUE_APP_ENV === 'development'
    },
    urlXML () {
      return `${this.isDev ? 'http://dtouch.local:3900' : 'https://cm-dviewcontent-api.dviewcontent.com'}/assets/weekly-menu/${this.editedItem.ID}/result.xml`
    },
  },
  mounted () {
    if (this.editedItem && this.editedItem.WeeklyMenuConfig) {
      this.config = typeof(this.editedItem.WeeklyMenuConfig) === 'string' ? JSON.parse(this.editedItem.WeeklyMenuConfig) : this.editedItem.WeeklyMenuConfig
      console.log(this.config)
      if (this.config.week) this.weekConfig = this.config.week
      if (this.config.meal) this.mealConfig = this.config.meal
      if (this.config.row) this.rowConfig = this.config.row
      if (this.config.background) this.background = this.config.background
    }
  },
  methods: {
    handleChange () {
      this.config = {
        background: this.background,
        meal: this.mealConfig,
        row: this.rowConfig,
        week: this.weekConfig,
      }
      if (this.newBackground) this.config.newBackground = this.newBackground

      this.onChange(this.config)
    },
    handleChangeBackground (v) {
      if (!v) return
      this.background = v.base64
      this.newBackground = v
      this.handleChange()
    },
    getFirstDayOfWeek () {
      let dayOfWeek = new Date().getDay()
      dayOfWeek = dayOfWeek === 0 ? 6 : dayOfWeek-1
      const result = new Date().setDate(new Date().getDate()-dayOfWeek)
      return new Date(result)
    },

    handleAddWeek () {
      let a = this.getFirstDayOfWeek()
      if (this.weekConfig.length > 0) {
        let tmp = this.weekConfig[this.weekConfig.length - 1].date
        if (tmp) {
          tmp = new Date(tmp)
          tmp = tmp.setDate(tmp.getDate()+7)
          a = new Date(tmp)
        }
      }
      this.weekConfig.push({
        id: utils.getNewID(),
        name: `Week ${this.weekConfig.length + 1}`,
        date: a.toISOString().split('T').shift(),
      })
      this.handleChange()
    },
    handleChangeWeek () {
      this.handleChange()
    },
    handleDeleteWeek (v) {
      this.weekConfig = this.weekConfig.filter(x => x.id !== v)
      this.handleChange()
    },

    handleAddMeal () {
      this.mealConfig.push({
        id: utils.getNewID(),
        name: `Comida ${this.mealConfig.length + 1}`
      })
    },
    handleChangeMeal (v) {
      const foundIndex = this.mealConfig.findIndex(x => x.id === v.id)
      this.mealConfig[foundIndex] = v
      this.handleChange()
    },
    handleDeleteMeal (v) {
      this.mealConfig = this.mealConfig.filter(x => x.id !== v.id)
      this.handleChange()
    },


    handleAddRow () {
      this.rowConfig.push({
        id: utils.getNewID(),
        name: `Plato ${this.rowConfig.length + 1}`
      })
    },
    handleChangeRow (v) {
      const foundIndex = this.rowConfig.findIndex(x => x.id === v.id)
      this.rowConfig[foundIndex] = v
      this.handleChange()
    },
    handleDeleteRow (v) {
      this.rowConfig = this.rowConfig.filter(x => x.id !== v.id)
      this.handleChange()
    },
  },
}
</script>

