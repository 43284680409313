<template>
  <v-flex xs12 style="margin: 10px 0 5px 0;">
    <v-text-field
      v-model="value"
      outlined
      label="Nombre opción"
      append-outer-icon="mdi-delete"
      dense
      hide-details
      @click:append-outer="onDelete(editedItem)"
    />
  </v-flex>
</template>
<script>
export default {
  props: {
    editedItem: {
      type: Object,
      required: true
    },
    onChange: {
      type: Function,
      required: true,
    },
    onDelete: {
      type: Function,
      required: true,
    },
  },
  data: () => ({
    value: null
  }),
  watch: {
    value () {
      this.handleChange()
    },
  },
  mounted () {
    this.prepareValue()
  },
  methods: {
    prepareValue () {
      this.value = this.editedItem.name
    },
    handleChange () {
      this.onChange({
        id: this.editedItem.id,
        name: this.value
      })
    },
  },
}
</script>

