var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.items)?_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"items-per-page":20,"hide-default-footer":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Categorías")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),(_vm.items.filter(function (x) { return x.edited; }).length === 0)?_c('v-btn',{attrs:{"color":"primary","dark":"","dense":""},on:{"click":_vm.handleAddItem}},[_vm._v(" Añadir ")]):_vm._e()],1)]},proxy:true},{key:"item.Name",fn:function(ref){
var item = ref.item;
return [(!item.edited)?_c('span',[_vm._v(" "+_vm._s(item.Name.es)+" ")]):_c('translate',{attrs:{"label":" ","languages":_vm.languages,"type":"text"},model:{value:(item.Name),callback:function ($$v) {_vm.$set(item, "Name", $$v)},expression:"item.Name"}})]}},{key:"item.Color",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"min-height":"140px","padding":"20px"}},[(!item.edited)?_c('div',{staticStyle:{"height":"120px","width":"100px"},style:({ backgroundColor: item.Color })}):_c('v-text-field',{staticStyle:{"margin-top":"20px"},attrs:{"hide-details":"","outlined":""},model:{value:(item.Color),callback:function ($$v) {_vm.$set(item, "Color", $$v)},expression:"item.Color"}})],1)]}},{key:"item.Icon",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"min-height":"140px","padding":"20px"}},[(!item.edited)?_c('v-img',{staticStyle:{"height":"120px","width":"200px"},attrs:{"src":item.Icon}}):_c('select-image',{staticStyle:{"width":"200px"},attrs:{"image":item.Icon,"onChange":function (v) { _vm.handleChangeIcon(item, v) },"locale":_vm.locale,"folder":_vm.workspaceID}})],1)]}},{key:"item.Image",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"min-height":"140px","padding":"20px"}},[(!item.edited)?_c('v-img',{staticStyle:{"height":"120px","width":"200px"},attrs:{"src":item.Image}}):_c('select-image',{staticStyle:{"width":"200px"},attrs:{"image":item.Image,"onChange":function (v) { _vm.handleChangeImage(item, v) },"locale":_vm.locale,"folder":_vm.workspaceID}})],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.edited === null || item.edited === undefined)?_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.handleEditItem(item)}}},[_vm._v(" mdi-pencil ")]):_vm._e(),(item.edited === null || item.edited === undefined)?_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.handleDeleteItem(item)}}},[_vm._v(" mdi-delete ")]):_vm._e(),(item.edited)?_c('v-icon',{attrs:{"small":""},on:{"click":_vm.handleClose}},[_vm._v(" mdi-close ")]):_vm._e()]}}],null,false,3624315205)}):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }