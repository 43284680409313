<template>
  <v-flex xs12 style="margin: 10px 0 5px 0;">
    <v-layout wrap style="background-color: #ffd68a;padding: 10px;">
      <v-flex xs4 style="padding-bottom:5px;">
        <v-text-field
          v-model="title"
          outlined
          label="Título ( Menú )"
          dense
          hide-details
        />
      </v-flex>
      <v-flex xs4 style="padding-bottom:5px;padding-left:10px;">
        <v-text-field
          v-model="titleColor"
          outlined
          label="Color título"
          dense
          hide-details
        />
      </v-flex>
      <v-flex xs4 style="padding-left: 10px;padding-bottom:5px">
        <v-text-field
          v-model="colorCircle"
          outlined
          label="Color circle ⚫"
          dense
          hide-details
        />
      </v-flex>
      <v-flex xs4 style="padding-top:5px;padding-left:0;">
        <v-text-field
          v-model="value"
          outlined
          label="Subtítulo"
          dense
          hide-details
        />
      </v-flex>
      <v-flex xs4 style="padding-left: 10px;padding-top:5px">
        <v-text-field
          v-model="colorMeal"
          outlined
          label="Color subtítulo ( comedor )"
          dense
          hide-details
        />
      </v-flex>
      <v-flex xs4 style="padding-left: 10px;padding-top:5px">
        <v-text-field
          v-model="icon"
          outlined
          label="Icono"
          append-outer-icon="mdi-delete"
          dense
          hide-details
          @click:append-outer="onDelete(editedItem)"
        />
      </v-flex>
    </v-layout>
  </v-flex>
</template>
<script>
export default {
  props: {
    editedItem: {
      type: Object,
      required: true
    },
    onChange: {
      type: Function,
      required: true,
    },
    onDelete: {
      type: Function,
      required: true,
    },
  },
  data: () => ({
    value: null,
    title: null,
    titleColor: null,
    icon: null,
    colorMeal: null,
    colorCircle: null,
  }),
  watch: {
    title () {
      this.handleChange()
    },
    titleColor () {
      this.handleChange()
    },
    value () {
      this.handleChange()
    },
    icon () {
      this.handleChange()
    },
    colorMeal () {
      this.handleChange()
    },
    colorCircle () {
      this.handleChange()
    },
  },
  mounted () {
    this.prepareValue()
  },
  methods: {
    prepareValue () {
      this.value = this.editedItem.name
      this.title = this.editedItem.title
      this.titleColor = this.editedItem.titleColor
      this.icon = this.editedItem.icon
      this.colorMeal = this.editedItem.colorMeal
      this.colorCircle = this.editedItem.colorCircle
    },
    handleChange () {
      console.log(this.icon)
      this.onChange({
        id: this.editedItem.id,
        name: this.value,
        title: this.title && this.title.length > 0 ? this.title : null,
        titleColor: this.titleColor && this.titleColor.length > 0 ? this.titleColor : null,
        icon: this.icon && this.icon.length > 0 ? this.icon : null,
        colorMeal: this.colorMeal && this.colorMeal.length > 0 ? this.colorMeal : null,
        colorCircle: this.colorCircle && this.colorCircle.length > 0 ? this.colorCircle : null,
      })
    },
  },
}
</script>

