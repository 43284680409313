<template>
  <v-row justify="center" style="padding: 10px 10px 0 10px;">
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          block
          color="primary"
          dark
          v-bind="attrs"
          v-on="on"
        >
          Editar estilo
        </v-btn>
      </template>
      <v-card>
        <v-toolbar
          dark
          color="primary"
        >
          <v-btn
            icon
            dark
            @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Estilos</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
              dark
              text
              @click="handleSave"
            >
              Guardar
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-textarea
          style="height:100%;"
          v-model="css"
        />
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
export default {
  props: {
    id: {
      type: String,
      default: null,
    },
    editedItem: {
      type: String,
      default: null
    },
    onChange: {
      type: Function,
      required: true,
    },
  },
  data: () => ({
    dialog: false,
    css: null,
  }),
  watch: {
    id () {
      this.prepareData()
    },
    editedItem () {
      this.prepareData()
    },
  },
  mounted () {
    this.prepareData()
  },
  methods: {
    prepareData () {
      if (this.editedItem) this.css = this.editedItem ? JSON.parse(this.editedItem) : null
    },
    handleSave () {
      if (this.css) this.onChange(JSON.stringify(this.css))
      this.dialog = false
    },
  },
}
</script>
