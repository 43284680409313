<template>
  <v-data-table
    v-if="items" 
    :headers="headers"
    :items="items"
    :items-per-page="20"
    class="elevation-1"
    hide-default-footer
  >
    <template v-slot:top>
      <v-toolbar
        flat
      >
        <v-toolbar-title>Categorías</v-toolbar-title>
        <v-divider
          class="mx-4"
          inset
          vertical
        />
        <v-spacer/>
          <v-btn 
            v-if="items.filter(x => x.edited).length === 0"
            color="primary" 
            dark 
            @click="handleAddItem"
            dense
          >
            Añadir
          </v-btn>
      </v-toolbar>
    </template>
  <template v-slot:item.Name="{ item }">
    <span
      v-if="!item.edited"
    >
    {{item.Name.es}}
    </span>
    <translate
      v-else
      v-model="item.Name"
      label=" "
      :languages="languages"
      type="text"
    />
  </template>
  <template v-slot:item.Color="{ item }">
    <div style="min-height: 140px;padding: 20px;">
      <div
        v-if="!item.edited"
        :style="{ backgroundColor: item.Color }"
        style="height:120px; width:100px;"
      />
      <v-text-field
        v-else
        v-model="item.Color"
        hide-details
        outlined
        style="margin-top: 20px;"
      />
    </div>
  </template>
  <template v-slot:item.Icon="{ item }">
    <div style="min-height: 140px;padding: 20px;">
    <v-img
      v-if="!item.edited"
      :src="item.Icon"
      style="height:120px; width:200px;"
    />
    <select-image 
      v-else
      :image="item.Icon"
      :onChange="(v) => { handleChangeIcon(item, v) }"
      :locale="locale"
      :folder="workspaceID"
        style="width:200px;"
    />
    </div>
  </template>
  <template v-slot:item.Image="{ item }">
    <div style="min-height: 140px;padding: 20px;">
    <v-img
      v-if="!item.edited"
      :src="item.Image"
      style="height:120px; width:200px;"
    />
    <select-image 
      v-else
      :image="item.Image"
      :onChange="(v) => { handleChangeImage(item, v) }"
      :locale="locale"
      :folder="workspaceID"
        style="width:200px;"
    />
    </div>
  </template>
  <template v-slot:item.actions="{ item }">
      <v-icon
        v-if="item.edited === null || item.edited === undefined"
        small
        class="mr-2"
        @click="handleEditItem(item)"
      >
        mdi-pencil
      </v-icon>
      <v-icon
        v-if="item.edited === null || item.edited === undefined"
        small
        @click="handleDeleteItem(item)"
      >
        mdi-delete
      </v-icon>
      <v-icon
        v-if="item.edited"
        small
        @click="handleClose"
      >
        mdi-close
      </v-icon>
    </template>
  </v-data-table>
</template>
<script>
const defaultItem = []
import utils from '@/services/utils'
import Translate from '@/components/Translate'
import SelectImage from '@/components/selectImage/Index'
export default {
  components: {
    Translate,
    SelectImage,
  },
  props: {
    workspaceID: {
      type: String,
      required: true,
    },
    editedItem: {
      type: Array,
      default: null,
    },
    onChange: {
      type: Function,
      required: true,
    },
    locale: {
      type: String,
      required: true,
    },
    languages: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    items: null,
  }),
  computed: {
    headers () {
      return [
        {
          text: 'Nombre',
          align: 'start',
          sortable: false,
          value: 'Name',
        },
        { text: 'Color', value: 'Color' },
        { text: 'Icon', value: 'Icon' },
        { text: 'Image', value: 'Image' },
        { text: '', value: 'actions' },
      ]
    },
    /*
    languages () {
      return ['es', 'en', 'fr', 'de', 'ca']
    },*/
  },
  mounted () {
    this.prepareData()
  },
  methods: {
    prepareData () {
      if (this.editedItem) {
        this.items = JSON.parse(JSON.stringify(this.editedItem))
      } else {
        this.items = JSON.parse(JSON.stringify(defaultItem.map(item => {
          item.ID = utils.getNewID()
          return item
        })))
        this.handleChange()
      }
    },
    handleAddItem () {
      this.items.push({
        ID: utils.getNewID(),
        Name: { es: 'Categoría' },
        Image: null,
        Color: 'red',
      })
      this.handleChange()
    },
    handleEditItem (v) {
      this.items = this.items.map(item => {
        item.edited = item.ID === v.ID ? true : false
        return item
      })
      this.handleChange()
    },
    handleDeleteItem (v) {
      this.items = this.items.filter(x => x.ID !== v.ID)
      this.handleChange()
    },
    handleClose () {
      this.items = this.items.map(item => {
        item.edited = null
        return item
      })
      this.handleChange()
    },
    handleChangeIcon (item, v) {
      if (!v) return 

      item.Icon = v.base64
      item.newIcon = v
      this.handleChange()
    },
    handleChangeImage (item, v) {
      if (!v) return 

      item.Image = v.base64
      item.newImage = v
      this.handleChange()
    },
    handleChange () {
      this.onChange(this.items)
    }
  }
}
</script>

