<template>
  <v-row>
    <v-col
      v-if="contentTypes"
      cols="12"
      sm="12"
      md="12"
    >
      <v-select
        v-model="mergeXML"
        :items="contentTypes"
        label="XML default"
        item-text="Name"
        item-value="ID"
        outlined
        hide-details
        @change="handleChange"
      />
        <small>este XML es el contenido que se muestra cuando no hay activa ninguna sala</small>
    </v-col>
    <v-col
      cols="12"
      sm="6"
      md="6"
    >
      <v-text-field
        v-bind="{ 'outlined' : true}"
        v-model="editedItem.Width"
        :label="$t('workspace.contentType.width', locale)"
        hide-details
      />
    </v-col>
    <v-col
      cols="12"
      sm="6"
      md="6"
    >
      <v-text-field
        v-bind="{ 'outlined' : true}"
        v-model="editedItem.Height"
        :label="$t('workspace.contentType.height', locale)"
        hide-details
      />
    </v-col>
    <v-col
      cols="12"
      sm="12"
      md="12"
    >
      <form-fields 
        v-if="!loading"
        :editedItem="formFields"
        :onChange="handleChangeFormFields"
      />
    </v-col>
    <v-col
      cols="12"
      sm="12"
      md="12"
    >
      <style-form
        :id="editedItem.ID"
        :editedItem="css"
        :onChange="handleChangeStyle"
      />
    </v-col>
  </v-row>
</template>
<script>
import api from '@/services/api'
import FormFields from './FormFields'
import StyleForm from './StyleForm'
export default {
  components: {
    FormFields,
    StyleForm,
  },
  props: {
    workspaceID: {
      type: String,
      required: true,
    },
    editedItem: {
      type: Object,
      required: true,
    },
    onChange: {
      type: Function,
      required: true,
    },
    locale: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    loading: true,
    contentTypes: null,
    mergeXML: null,
    formFields: null,
    css: null,
  }),
  watch: {
    'editedItem.ID' () {
      this.prepareData()
      this.handleGetData()
    },
  },
  mounted () {
    this.prepareData()
    this.handleGetData()
  },
  methods: {
    prepareData () {
      this.mergeXML = null
      if (!this.editedItem || !this.editedItem.MeetingRoomConfig) return
      const aux = typeof(this.editedItem.MeetingRoomConfig) === 'object' ? this.editedItem.MeetingRoomConfig : JSON.parse(this.editedItem.MeetingRoomConfig)
      this.mergeXML = aux.mergeXML
    },
    handleGetData () {
      this.loading = true
      if (this.editedItem.ID) {
        api.getItem ('dviewcontent', 'v1/system/content-types/', this.editedItem.ID.concat('/meeting-room'))
          .then(response => {   
            this.mergeXML = response.MergeXML
            this.formFields = response.formFields
            try {
              this.css = response.CSS // ? JSON.parse(response.CSS) : null
            } catch (err) {
              console.log(err)
            }
            this.loading = false
          })
      } else this.loading = false

      api.getAllWithoutLimit ('dviewcontent', `private/workspaces/${this.workspaceID}/content-types`)
        .then(response => {
          this.contentTypes = response.filter(x => x.Type === 'media').map(item => { return { ID: item.ID, Name: item.Name } })
        })
    },
    handleChangeFormFields (v) {
      this.formFields = v
      this.handleChange()
    },
    handleChangeStyle (v) {
      this.css = v
      this.handleChange()
    },
    handleChange () {
      this.onChange({ 
        mergeXML: this.mergeXML,
        formFields: this.formFields,
        css: this.css
      })
    },
  },
}
</script>

